<template>
  <div id="sub-users">
    <van-sticky :offset-top="0">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />添加子账号
        </van-col>
        <!-- <van-col span="14" style="text-align: right" @click="onAddClick">
          <svg-icon :data_iconName="'add-white'" :className="'add-icon'" />
        </van-col> -->
      </van-row>
    </div>
    </van-sticky>
    <div class="users-container">
      <div class="form">
        <div class="item">
          <input
            type="text"
            name="username"
            placeholder="请输入用户名"
            autocomplete="off"
            class="user"
            v-model="user.username"
          />
        </div>
        <div class="item">
          <input
            type="text"
            name="mobile"
            maxlength="11"
            placeholder="请输入手机号"
            autocomplete="off"
            class="name"
            v-model="user.mobile"
          />
        </div>
        <div class="item">
          <input
            type="password"
            name="password"
            placeholder="请输入密码"
            autocomplete="off"
            class="password"
            v-model="user.password"
          />
        </div>
        <div class="item">
          <input
            type="password"
            name="password"
            placeholder="请确认密码"
            autocomplete="off"
            class="password"
            v-model="user.password_confirmation"
          />
        </div>

        <!-- <p>忘记密码</p> -->
        <div class="submit"><button @click="add_user">确认</button></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "add-subuser",
  data() {
    return {
      user: {
        mobile: "",
        username: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    validatemobile() {
      // return true;
      //判断输入的手机号是否合法
      if (!this.user.mobile) {
        Toast("请输入手机号");
        return false;
      } else if (!/^1[345678]\d{9}$/.test(this.user.mobile)) {
        Toast("请输入正确的手机号!");
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
    async add_user() {
      if (!this.user.username) {
        Toast("请填写用户名称");
        return false;
      }
      if (this.user.mobile == "") {
        Toast("请填写登录手机号");
        return false;
      }
      if (this.user.password == "") {
        Toast("请填写登录密码");
        return false;
      }
      if (this.user.password.length < 6) {
        Toast("密码长度必须大于6位");
        return false;
      }
      if (this.user.password != this.user.password_confirmation) {
        Toast("两次密码输入不一致");
        return false;
      }
      if (this.validatemobile) {
        const data = {
          user: this.user,
        };
        const res = await this.$apis.add_user(data);
        if (res.status == 200) {
          Toast("创建成功");
          this.$router.go(-1);
        }
      }
    },
  },
  created() {},
};
</script>
<style scoped>
@import "../register/style.css";
#sub-users {
  height: 100vh;
  /* padding-top:10vh; */
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.add-icon {
  font-size: 0.6rem;
}
.home-body-header {
  padding: 0.2rem 0rem;
  position: relative;
}
.home-body-header .avatar {
  height: 0.9rem;
  width: 0.9rem;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
}
.home-body-header .username {
  color: #fff;
  font-size: 0.3rem;
  font-weight: bold;
}
.home-body-header .mobile {
  color: #fff;
  font-size: 0.25rem;
  opacity: 0.74;
  margin-top: 4px;
}
.users-container {
  padding: 0.4rem;
}
</style>